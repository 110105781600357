import { LitElement, html, css } from "lit-element";

// https://developers.google.com/web/fundamentals/media/mobile-web-video-playback?hl=it
// https://github.com/bennypowers/shaka-player

const hideControlTimeout = 5000;

const STATE_TEXT = [
  "HAVE_NOTHING",
  "HAVE_METADATA",
  "HAVE_CURRENT_DATA",
  "HAVE_FUTURE_DATA",
  "HAVE_ENOUGH_DATA",
];

// Extend the LitElement base class
class MyPlayer extends LitElement {
  constructor() {
    super();
    this._hideControlsTimer = undefined;
    this._hideControlsTimer2 = undefined;
    this._videoControls = null;
    this._progressContainer = null;
    this._controlsVisible = false;

    this.autoplay = false;
    this.controls = false;
    this.debug = false;
    this.isPlaying = false;
    this.progress = 0.0;

    this.callStack = [];

    this.playbackRate = 1;

    this._firstUpdatedDone = false;
    this._mouseOnvideoControlsArea = false;  // True if mouse is in control area (prevent hide)
  }

  static get styles() {
    return [
      //super.styles,
      css`
        :host {
          width: 100%;
          height: 100%;
          user-select: none;
        }
        video {
          width: 100%;
          height: 100%;
        }
        .visible {
          opacity: 1 !important;
        }
        #videoContainer {
          position: relative;
          height: 100%;
          width: 100%;
          margin: 0 auto;
        }
        #video {
          position: absolute;
          will-change: filter;
          transition: filter ease-out 0.2s;
        }
        #videoControls {
          position: absolute;
          opacity: 0;
          bottom: 0px;
          margin-bottom: 1%;
          min-height: 50px;
          height: 50px;
          transition: opacity 0.35s cubic-bezier(0, 0, 0.4, 1);
        }
        #progressContainer {
          opacity: 0;
          position: absolute;
          height: 3px;
          width: 100%;
          bottom: 0px;
          transition: opacity 0.35s cubic-bezier(0, 0, 0.4, 1);
        }
        #progressBar {
          height: 4px;
          width: 0%;
          background-color: #007bff;
        }

        #fullscreenArea {
          border: 1px solid red;
          position: absolute;
          top: 10px;
          bottom: 80px;
          left: 10px;
          right: 10px;
        }

        #debugInfo {
          border: 1px solid blue;
          position: absolute;
          top: 20px;
          left: 20px;
          width: 200px;
          height: 110px;
          background-color: #ccc;
          opacity: 0.7;
          font-weight: normal;
          font-size: 11px;
          overflow: auto;
          white-space: nowrap;
          display: none;
        }

        #pauseButton,
        #playButton,
        #backwardButton,
        #previousButton,
        #forwardButton,
        #nextButton {
          cursor: pointer;
        }
        #previousButton:hover path,
        #backwardButton:hover path,
        #playButton:hover path,
        #pauseButton:hover path,
        #forwardButton:hover path,
        #nextButton:hover path {
          fill: #0056b3;
        }
      `,
    ];
  }

  static get properties() {
    return {
      autoplay: { type: Boolean },
      controls: { type: Boolean },
      isPlaying: { type: Boolean, attribute: "is-playing" },
      loading: { type: Boolean },
      progress: { type: Number, attribute: "progress" },
      debug: { type: Boolean, attribute: "debug" },

      playbackRate: { type: Number },
    };
  }

  attributeChangedCallback(name, oldval, newval) {
    if (this._firstUpdatedDone) {
      switch (name) {
        case "progress":
          this.setProgressBar(newval);
          break;
        case "debug":
          this.setDebug(newval != "false");
          break;
        case "controls":
          this.setControls(newval != null);
          break;
        case "is-playing":
          this.setIsPlaying(newval == "true");
          break;
      }
    }
    super.attributeChangedCallback(name, oldval, newval);
  }

  render() {
    return html`
      <div id="videoContainer">
        <video
          id="video"
          autoplay="${this.autoplay}"
          @pause="${(event) => this.onVideoPause(event)}"
          @play="${(event) => this.onVideoPlay(event)}"
          @timeupdate="${(event) => this.onVideoTimeUpdate(event)}"
        ></video>
        <div id="fullscreenArea"></div>
        <div id="debugInfo"></div>
        <div id="progressContainer">
          <div id="progressBar"></div>
        </div>
        <div id="videoControls" xclass="visible">
          <!-- Prev. track -->
          <svg
            id="previousButton"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
          >
            <path
              d="M6 6h2v12H6zm3.5 6l8.5 6V6z"
              stroke="white"
              stroke-width="0.3"
              fill="#007bff"
            />
          </svg>
          <!-- Fast backward -->
          <svg
            id="backwardButton"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            style="padding: 0 10px 0 5px"
            @click="${this.onBackwardClick}"
          >
            <path
              d="M11 18V6l-8.5 6 8.5 6zm.5-6l8.5 6V6l-8.5 6z"
              stroke="white"
              stroke-width="0.3"
              fill="#007bff"
            />
          </svg>

          <!-- Pause -->
          <svg
            id="pauseButton"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            style="display: none"
            @click="${this.onPlayPauseClick}"
          >
            <path
              d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"
              stroke="white"
              stroke-width="0.3"
              fill="#007bff"
            />
          </svg>
          <!-- Play -->
          <svg
            id="playButton"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            @click="${this.onPlayPauseClick}"
          >
            <path
              d="M8 5v14l11-7z"
              stroke="white"
              stroke-width="0.3"
              fill="#007bff"
            />
          </svg>
          <!-- fast forward -->
          <svg
            id="forwardButton"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            style="padding: 0 5px 0 10px"
            @click="${this.onForwardClick}"
          >
            <path
              d="M4 18l8.5-6L4 6v12zm9-12v12l8.5-6L13 6z"
              stroke="white"
              stroke-width="0.3"
              fill="#007bff"
            />
          </svg>
          <!-- Next song -->
          <svg
            id="nextButton"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
          >
            <path
              d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z"
              stroke="white"
              stroke-width="0.3"
              fill="#007bff"
            />
          </svg>
        </div>
      </div>
    `;
  }

  firstUpdated(changedProperties) {
    this._firstUpdatedDone = true;
    this._videoContainer = this.shadowRoot.getElementById("videoContainer");
    this._videoControls = this.shadowRoot.getElementById("videoControls");
    this._progressContainer = this.shadowRoot.getElementById(
      "progressContainer"
    );
    this._progressBar = this.shadowRoot.getElementById("progressBar");
    this._video = this.shadowRoot.getElementById("video");
    this._previousButton = this.shadowRoot.getElementById("previousButton");
    this._backwardButton = this.shadowRoot.getElementById("backwardButton");
    this._playButton = this.shadowRoot.getElementById("playButton");
    this._pauseButton = this.shadowRoot.getElementById("pauseButton");
    this._forwardButton = this.shadowRoot.getElementById("forwardButton");
    this._nextButton = this.shadowRoot.getElementById("nextButton");
    this._debugInfo = this.shadowRoot.getElementById("debugInfo");
    this._fullscreenArea = this.shadowRoot.getElementById("fullscreenArea");

    this.addEventListener("mouseenter", this.onMouseEnter);
    this.addEventListener("mousemove", this.onMouseMove);
    this.addEventListener("mouseleave", this.onMouseLeave);
    this.shadowRoot
      .getElementById("fullscreenArea")
      .addEventListener("click", (e) => {
        this.onFullscreenRequest();
        e.stopPropagation();
      });
    this._previousButton.addEventListener("click", (e) => {
      this.onPreviousTrackClick();
    });
    this._backwardButton.addEventListener("click", (e) => {
      this.onSeekBackwardClick();
    });
    this._playButton.addEventListener("click", (e) => {
      this.onPlayClick();
    });
    this._pauseButton.addEventListener("click", (e) => {
      this.onPauseClick();
    });
    this._forwardButton.addEventListener("click", (e) => {
      this.onSeekForwardClick();
    });
    this._nextButton.addEventListener("click", (e) => {
      this.onNextTrackClick();
    });

    const ro = new ResizeObserver((entries) => {
      this._updateButtonSize();
    });
    ro.observe(this.shadowRoot.getElementById("videoContainer"));

    this._initPlayer();
    this.setDebug(this.debug);
    this.setControls(this.controls);
  }

  get width() {
    if (this.isFullscreen) {
      return window.innerWidth;
    } else {
      return parseFloat(getComputedStyle(this).getPropertyValue("width"), 10);
    }
  }
  get height() {
    if (this.isFullscreen) {
      return window.innerHeight;
    } else {
      return parseFloat(getComputedStyle(this).getPropertyValue("height"), 10);
    }
  }
  get playbackRate() {
    if (this._video) {
      return this._video.playbackRate;
    }
    return 1;
  }
  set playbackRate(playbackRate) {
    if (this._video) {
      this._video.playbackRate = playbackRate;
    }
  }

  _updateButtonSize() {
    let size = 48;
    if (this.width < 300) {
      size = 36;
    } else if (this.width > 2000) {
      size = 72;
    }

    for (let btn of [
      "_previousButton",
      "_backwardButton",
      "_playButton",
      "_pauseButton",
      "_forwardButton",
      "_nextButton",
    ]) {
      this[btn].setAttribute("width", `${size}`);
      this[btn].setAttribute("height", `${size}`);
    }
    this._videoControls.style.width = `${(size + 10) * 5}px`;
    this._videoControls.style.left = (this.width - (size + 10) * 5) / 2 + "px";
  }
  _initPlayer() {
    this.debugInfo("_initPlayer");
    this._updateButtonSize();
    shaka.polyfill.installAll();
    if (!shaka) {
      throw "Missing 'shaka' element";
    }
    this._isBrowserSupported = shaka.Player.isBrowserSupported();
    if (!this._isBrowserSupported) {
      return;
    }
    this._player = new shaka.Player(this._video);

    /*this._player.configure({
      streaming: {
        bufferingGoal: 5
      }
    });*/
    this._setMaxWidth(250);
  }
  _setMaxWidth(width) {
    // Playback issues
    return;
    this._player.configure({
      abr: {
        restrictions: { maxWidth: width },
      },
      restrictions: { maxWidth: width },
    });
    console.log(`Apply maxWidth(${width})`);
    console.log(this._player.getConfiguration());
  }

  timeToMMSS(time) {
    const h = Math.floor(time / 60),
      m = Math.floor(time) % 60;
    if (m < 10) {
      return `${h}:0${m}`;
    }
    return `${h}:${m}`;
  }
  getDebugLine(title, value) {
    return `<b>${title}</b>: ${value}`;
  }
  debugInfo(functionName) {
    if (!this._firstUpdatedDone || !this.debug) {
      return;
    }
    if (functionName) {
      this.callStack.push(functionName);
    }
    if (this.callStack.length > 10) {
      this.callStack.shift();
    }
    const lines = [];
    lines.push(this.getDebugLine("Call stack", this.callStack.join(", ")));
    lines.push(this.getDebugLine("URL", this._video.src));
    lines.push(this.getDebugLine("State", STATE_TEXT[this._video.readyState]));
    lines.push(
      this.getDebugLine(
        "Time",
        `Video: ${this.timeToMMSS(this._video.currentTime)}/${this.timeToMMSS(
          this._video.duration
        )} - Audio: ${Math.round(this.progress, 1)}%`
      )
    );

    lines.push(
      this.getDebugLine(
        "Speed",
        `${Math.round(this._video.playbackRate * 100) / 100}x`
      )
    );

    this._debugInfo.innerHTML = lines.join("<br>");
  }

  /**
   * Load a regular video URL.
   * @param  {String} url
   * @return {any}
   */
  async loadVideo(url) {
    this.debugInfo(`loadVideo(${url})`);
    this._video.src = url;
  }

  /**
   * Load a manifest URL into shaka player.
   * @param  {String}  manifestUri
   * @param  {Object}  [player=this.player] handle on shaka player instance
   * @return {Promise}  Resolved when the manifest has been loaded and playback has begun; rejected when an error occurs or the call was interrupted by destroy(), unload() or another call to load().
   */
  async loadManifest(manifestUri, videoStartTime) {
    if (!this._player) throw new Error("Could not load player");
    if (!manifestUri) return;

    //this.internalLoadManifest(manifestUri, videoStartTime);
    // If another load was in progress, wait for it to complete.
    //await this.loadManifestPromise;
    //await this.playPromise;
    // If the player is already initialized, unload it's sources.

    // RIVEDERE UNLOAD
    //if (this._player.getManifest()) {
    //await this._player.unload();
    //}
    //console.log(this._player);
    //await this._player.load(manifestUri, videoStartTime);
  }

  /**
   * Load a Manifest.
   *
   * @param  {String}  manifestUri
   * @param  {number}  [startTime] Optional start time, in seconds, to begin playback. Defaults to 0 for VOD and to the live edge for live. Set a positive number to start with a certain offset the beginning. Set a negative number to start with a certain offset from the end. This is intended for use with live streams, to start at a fixed offset from the live edge.
   * @param  {shakaExtern.ManifestParser.Factory} [manifestParserFactory] Optional manifest parser factory to override auto-detection or use an unregistered parser.
   * @param  {Object}  [player=this.player] handle on shaka player instance
   * @return {Promise}  Resolved when the manifest has been loaded and playback has begun; rejected when an error occurs or the call was interrupted by destroy(), unload() or another call to load().
   */
  /*async internalLoadManifest(manifestUri, videoStartTime) {
    this.loadManifestPromise = this._player
      .load(manifestUri, videoStartTime)
      .then(this.onManifestLoaded.bind(this))
      .catch(this.onPlayerLoadError.bind(this));
    return this.loadManifestPromise;
  }*/

  async playManifest(manifestUri, videoStartTime) {
    this.debugInfo(`playManifest(${this.startPlay})`);

    if (this.startPlay === true) {
      // cache for later...
      //return;
    }
    this.startPlay = true;

    if (!this._player) throw new Error("Could not load player");
    if (!manifestUri) return;
    //this.debugInfo(`playManifest(${manifestUri}, ${videoStartTime})`);

    this.playbackRate = 1;
    //await this.loadManifest(manifestUri, videoStartTime);
    try {
      await this._player.load(manifestUri, videoStartTime);
    } catch (e) {
      console.error(e);
      this.startPlay = false;
    }
    //await this._video.play();
    this.startPlay = false;
  }

  async pause() {
    const { video } = this;
    return video && video.play().then(video.pause.bind(video));
  }

  async play(maxRetry = 100) {
    if (this.playTimer) {
      clearTimeout(this.playTimer);
      this.playTimer = null;
    }
    if (this.canPlay) {
      await this.video.play();
    } else {
      this.playTimer = await setTimeout(async () => {
        if (maxRetry > 0) {
          await this.play(maxRetry - 1);
        }
      }, 250);
    }

    return;
    const { video } = this;
    this.playPromise = !video
      ? Promise.reject("No Player")
      : this.canPlay
      ? video.play()
      : // There may be times when a user tries to call play() when there are no sources available.
        // In that case, `playPromise` must be undefined, in case the user needs to await it.
        // However, we'd still like to pass as much of the behavior through to the video element.
        video.play() && undefined;
    return this.playPromise;
  }

  showControls(autoHide = true) {
    this._videoContainer.style.cursor = "default";
    if (!this.isFullscreen) {
      // Controls only on full screen
      return;
    }
    this._controlsVisible = true;
    this._videoControls.style.display = "";

    this._videoControls.classList.add("visible");
    this._progressContainer.classList.add("visible");
    clearTimeout(this._hideControlsTimer);
    clearTimeout(this._hideControlsTimer2);
    if (autoHide && !this._mouseOnvideoControlsArea) {
      this._hideControlsTimer = setTimeout(() => {
        this.hideControls();
      }, hideControlTimeout);
    }
  }

  hideControls() {
    clearTimeout(this._hideControlsTimer);
    this._videoControls.classList.remove("visible");
    this._progressContainer.classList.remove("visible");
    this._hideControlsTimer = undefined;

    clearTimeout(this._hideControlsTimer2);
    this._hideControlsTimer2 = setTimeout(() => {
      this._videoControls.style.display = "none";
      this._controlsVisible = false;
      if (this.isFullscreen) {
        this._videoContainer.style.cursor = "none";
      }
    }, 1000);
  }

  onMouseEnter(e) {
    if (this.isFullscreen) {
      this.showControls();
    }
  }
  onMouseMove(e) {
    if (this.isFullscreen) {
      var rect = this._videoControls.getBoundingClientRect();
      this._mouseOnvideoControlsArea = 
        e.clientX >= rect.left -10 && e.clientX <= rect.right + 10 &&
        e.clientY >= rect.top - 10 && e.clientY <= rect.bottom + 10
      setTimeout(() => {
        this.showControls();
      }, 0);
    } else {
      this._videoContainer.style.cursor = "default";
    }
  }

  onMouseLeave(e) {
    this.hideControls();
  }

  // Button events
  onPreviousTrackClick(e) {
    if (!this._controlsVisible) {
      return;
    }
    this.dispatchEvent(
      new CustomEvent("previoustrack", { bubbles: true, composed: true })
    );
  }
  onSeekBackwardClick(e) {
    if (!this._controlsVisible) {
      return;
    }
    this.dispatchEvent(
      new CustomEvent("seekbackward", { bubbles: true, composed: true })
    );
  }
  onPlayClick(e) {
    if (!this._controlsVisible) {
      return;
    }
    this.dispatchEvent(
      new CustomEvent("play", { bubbles: true, composed: true })
    );
  }
  onPauseClick(e) {
    if (!this._controlsVisible) {
      return;
    }
    this.dispatchEvent(
      new CustomEvent("pause", { bubbles: true, composed: true })
    );
  }
  onSeekForwardClick(e) {
    if (!this._controlsVisible) {
      return;
    }
    this.dispatchEvent(
      new CustomEvent("seekforward", {
        bubbles: true,
        composed: true,
      })
    );
  }
  onNextTrackClick(e) {
    if (!this._controlsVisible) {
      return;
    }
    this.dispatchEvent(
      new CustomEvent("nexttrack", { bubbles: true, composed: true })
    );
  }

  onPlayPauseClick(e) {
    this.showControls();
    if (this._video.paused) {
      //this._playButton.classList.add("visible");
      //this._pauseButton.classList.add("visible");
      this._video.play();
    } else {
      this._video.pause();
    }
  }

  async onFullscreenRequest() {
    if (this.isFullscreen) {
      if (!this._controlsVisible) {
        return;
      }
      this.controls = false;
      document.exitFullscreen();
      setTimeout(() => {
        this.hideControls();
      }, 10);
      this._setMaxWidth(250);
    } else {
      this._setMaxWidth(Infinity);
      this.controls = true;
      await this._videoContainer.requestFullscreen({ navigationUI: "hide" });
      try {
        await screen.orientation.lock("landscape");
      } catch (e) {
        console.log(e);
      }
      //screen.orientation.lock("natural");
    }
  }

  /**
   * Updates properties on pause.
   * @param  {Event} event pause event
   * @protected
   */
  onVideoPause(event) {
    return;
    this.showControls();
    this._playButton.style.display = "";
    this._pauseButton.style.display = "none";

    this.dispatchEvent(
      new CustomEvent("XXX-playing-changed", { value: this.playing })
    );
  }

  /**
   * Updates properties on pause.
   * @param  {Event} event pause event
   * @protected
   */
  onVideoPlay(event) {
    return;
    this._playButton.style.display = "none";
    this._pauseButton.style.display = "";

    //this.dispatchEvent(new CustomEvent("onPlay", { value: this.playing }));
    //this.dispatchEvent(new CustomEvent("Play", { value: this.playing }));
    this.dispatchEvent(new CustomEvent("xxxplay", { value: this.playing })); // videoplay
  }

  setIsPlaying(isPlaying) {
    if (!this._firstUpdatedDone) {
      return;
    }
    this.isPlaying = isPlaying;
    if (this.isPlaying) {
      this._playButton.style.display = "none";
      this._pauseButton.style.display = "inline";
    } else {
      this._playButton.style.display = "inline";
      this._pauseButton.style.display = "none";
    }
    //this._playButton.style.display = "";
    //this._pauseButton.style.display = "";
  }

  setProgressBar(position) {
    if (!this._firstUpdatedDone) {
      return;
    }
    this.debugInfo();
    if (position > 0) {
      this.position = position;
    } else {
      this.position = 0;
    }
    this._progressBar.style.width = `${this.position}%`;
  }

  setDebug(debug) {
    if (!this._firstUpdatedDone) {
      return;
    }
    this.debug = debug;
    if (this.debug) {
      this._debugInfo.style.display = "block";
      this._fullscreenArea.style.border = "1px solid red";
    } else {
      this._debugInfo.style.display = "none";
      this._fullscreenArea.style.border = "0";
    }
    this.debugInfo();
  }

  setControls(controls) {
    if (!this._firstUpdatedDone) {
      return;
    }
    this.controls = controls;
    if (this.controls && this.isFullscreen) {
      this._fullscreenArea.style.bottom = "80px";
    } else {
      this._fullscreenArea.style.bottom = "10px";
    }
  }

  onVideoTimeUpdate(event) {
    this.debugInfo();
    // La barra di progressione è relativa alla musica non al video!
    /*if (this._video.duration > 0) {
      this._progressBar.style.width = `${(this._video.currentTime /
        this._video.duration) *
        100}%`;
    } else {
      this._progressBar.style.width = 0;
    }*/
  }

  onBackwardClick(event) {
    this.showControls();
    this._video.currentTime = this._video.currentTime - 10;
  }
  onForwardClick(event) {
    this.showControls();
    this._video.currentTime = this._video.currentTime + 10;
  }

  onError(event) {
    this.loading = false;
    this.dispatchEvent(new ErrorEvent(event.type, event));
    this.dispatchEvent(customEvent("playing-changed", { value: this.playing }));
  }

  /**
   * Updates Properties when playback ends.
   * @param  {Event} event ended event
   * @protected
   */
  onEnded(event) {
    alert("a2");
    this.dispatchEvent(customEvent("playing-changed", { value: this.playing }));
  }

  /**
   * Updates fullscreen property when fullscreen changes.
   * @param  {Event} event fullscreenchange event
   * @protected
   */
  onFullscreenchange(event) {
    alert("a3");
    this.fullscreen = !!(document.fullscreen || document.fullscreenElement);
  }

  /**
   * Updates properties when loading starts
   * @param  {Event} event loadstart event
   * @protected
   */
  onLoadstart(event) {
    alert("a4");
    this.loading = true;
  }

  /**
   * Updates properties on pause.
   * @param  {Event} event pause event
   * @protected
   */
  onPause(event) {
    alert("a5");
    this.dispatchEvent(customEvent("playing-changed", { value: this.playing }));
  }

  /**
   * Updates properties on play.
   * @param  {Event} event play event
   * @protected
   */
  onPlay(event) {
    alert("a6");
    this.dispatchEvent(customEvent("playing-changed", { value: this.playing }));
  }

  /**
   * Handles load errors.
   * @param  {Error} error
   * @param  {String} [src=this.src] video uri
   * @return {String|Promise}
   * @protected
   */
  onPlayerLoadError(error) {
    console.error(error);
    //alert("a7");
    //this.dispatchEvent(errorEvent("error", error));
    // eslint-disable-next-line no-unused-vars
    //const { code, category, data, severity } = error;
    //const networkError = code === 1002; // HTTP_ERROR
    //const videoError = code === 3016; // VIDEO_ERROR
    //const manifestError = code >= 4000 && code < 5000;
    //const errorIsFinal = networkError || manifestError || videoError;
    //return errorIsFinal ? src && this.loadVideo(src) : undefined;
  }

  get disabled() {
    return this._disabled;
  }

  // Set video
  set disabled(val) {
    this._disabled = val;
    if (this._disabled) {
      alert("STOP PLAY");
      //this.pause();
    } else {
      alert("START PLAY");
      //this.loadVideo(this._lastTrackNo, true);
    }
  }

  get isFullscreen() {
    return document.fullscreenElement !== null;
  }

  /**
   * Whether the video can play.
   * @type {Boolean}
   */
  get canPlay() {
    //return !!(this._video && this._video.readyState > 0);
    return !!(this._video && this._video.readyState > 1);
  }

  /**
   * The currentTime of the video in seconds.
   * @type {Number}
   */
  get currentTime() {
    return (this._video && this._video.currentTime) || 0;
  }

  // Set video
  set currentTime(val) {
    // BLOCK ME!
    if (!this._video) return;
    this._video.currentTime = val;
  }

  /**
   * The duration of the video in seconds.
   * @type {Number}
   */
  get duration() {
    return (this._video && this._video.duration) || 0;
  }

  /**
   * Whether or not the video playback has ended.
   * @type {Boolean}
   */
  get ended() {
    return this._video && this._video.ended;
  }

  /**
   * Whether the video is paused.
   * @type {Boolean}
   */
  get paused() {
    return this._video && this._video.paused;
  }

  /**
   * Whether the player is playing.
   * @type {Boolean}
   */
  get playing() {
    return (
      this.loading !== true &&
      this.currentTime > 0 &&
      !this.paused &&
      !this.ended
    );

    /*this.videoPlayer.currentTime > 0 &&
      !this.videoPlayer.paused &&
      !this.videoPlayer.ended &&
      this.videoPlayer.readyState >= this.videoPlayer.HAVE_CURRENT_DATA*/
  }

  /**
   * Ready state of the video element.
   * see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
   */
  get readyState() {
    return (this._video && this._video.readyState) || 0;
  }

  /**
   * Ready state of the video element.
   * see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
   */
  get readyState() {
    return (this._video && this._video.readyState) || 0;
  }

  /**
   * The src URL for the video file.
   * @type {String}
   */
  get src() {
    return this._getProperty("src");
  }

  set src(src) {
    alert("a9");
    this._setProperty("src", src);
    return this.loadVideo(src);
  }

  /**
   * The underlying video element.
   * @type {HTMLVideoElement}
   */
  get video() {
    //alert("a10");
    return this.shadowRoot &&
      typeof this.shadowRoot.querySelector === "function"
      ? this.shadowRoot.querySelector("video")
      : undefined;
  }

  /**
   * Dispatches 'manifest-loaded' event.
   *
   * @protected
   * @fires 'manifest-loaded'
   * @param  {any} loaded
   */
  onManifestLoaded(loaded) {
    //this.dispatchEvent(customEvent("manifest-loaded", loaded));
  }
}
// Register the new element with the browser.
customElements.define("my-player", MyPlayer);
